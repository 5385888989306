import { t } from '@lingui/macro'
import { allowAnalyticsAtom } from 'analytics'
import { useAtom } from 'jotai'

import { SettingsToggle } from './SettingsToggle'

export function AnalyticsToggle() {
  const [allowAnalytics, updateAllowAnalytics] = useAtom(allowAnalyticsAtom)

  return (
    <></>
  )
}
