import styled from "styled-components";
import Row, { RowBetween, RowFixed } from "../Row";

const StyledAirdropHeader = styled(RowBetween)`
  margin-bottom: 16px;
  color: ${({ theme }) => theme.neutral2};
  justify-content: center
`;

const HeaderButtonContainer = styled(RowFixed)`
  padding: 0 12px;
  gap: 16px;
`;

const StyledTextButton = styled(Row)`
  color: ${({ theme }) => theme.neutral1};
  gap: 4px;
  font-weight: 485;
  font-size: 22px;
  &:focus {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
`;

export default function AirdropHeader() {
  return (
    <StyledAirdropHeader>
      <HeaderButtonContainer>
        <StyledTextButton>
          Donate
        </StyledTextButton>
      </HeaderButtonContainer>
    </StyledAirdropHeader>
  );
}
